import { createContext, useEffect, useState, useContext } from 'react';
import { SupervisorContext } from './SupervisorContext';
import StatisticsHeader from 'src/components/statistics/StatisticsHeader';
import { getCurrentMonth, getCurrentWeek, getCurrentYear } from 'src/components/utils/DateUtils';
import { StatisticsAPI } from 'src/api';
import IntervalSelect from 'src/components/statistics/IntervalSelect';
import LoadingPage from 'src/router/LoadingPage';
import Typography from '@mui/material/Typography';
import { AppContext } from './AppContext';

type intervalType = 'day' | 'week' | 'month' | 'year';

type props = {
  children?: React.ReactNode;
};

export type StatisticsContextType = {
  intervalType?: intervalType;
  setIntervalType: (newVal: intervalType) => void;
  interval: {
    start: Date;
    end: Date;
  };
  setInterval: (newVal: { start: Date; end: Date }) => void;
  nextPeriod: () => void;
  prevPeriod: () => void;
  data: any;
};

export const StatisticsContext = createContext<StatisticsContextType>({} as StatisticsContextType);

export const StatisticsScene = ({ children }: props) => {
  const { currentClient, isSupervising } = useContext(SupervisorContext);
  const { user } = useContext(AppContext);

  const [intervalType, setIntervalType] = useState<intervalType>('week');
  const [interval, setInterval] = useState<{ start: Date; end: Date }>({
    start: new Date(),
    end: new Date(),
  });

  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (intervalType === 'day') {
      setInterval({
        start: new Date(),
        end: new Date(),
      });
    } else if (intervalType === 'week') {
      const { startDate, endDate } = getCurrentWeek();
      setInterval({
        start: startDate,
        end: endDate,
      });
    } else if (intervalType === 'month') {
      const { startDate, endDate } = getCurrentMonth();
      setInterval({
        start: startDate,
        end: endDate,
      });
    } else if (intervalType === 'year') {
      const { startDate, endDate } = getCurrentYear();
      setInterval({
        start: startDate,
        end: endDate,
      });
    }
  }, [intervalType]);

  const fetchData = async () => {
    setData({});
    setLoading(true);
    const res = await new StatisticsAPI().getStatistics(
      {
        startDate: interval.start,
        endDate: interval.end,
        supervisedTag: currentClient ? currentClient.tag : undefined,
      },
      intervalType,
    );
    setLoading(false);
    setData(res);
  };

  const fetchDailyData = async () => {
    setData({});
    setLoading(true);

    const res = await new StatisticsAPI().getDailyStatistics({
      date: interval.start,
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    setLoading(false);
    setData(res);
  };

  useEffect(() => {
    if (intervalType === 'day') fetchDailyData();
    else fetchData();
  }, [interval]);

  const nextPeriod = () => {
    goToPeriod(+1);
  };

  const prevPeriod = () => {
    goToPeriod(-1);
  };

  const goToPeriod = (period: number) => {
    if (intervalType === 'day') {
      setInterval({
        start: new Date(interval.start.setDate(interval.start.getDate() + 1 * period)),
        end: new Date(interval.end.setDate(interval.end.getDate() + 1 * period)),
      });
    } else if (intervalType === 'week') {
      setInterval({
        start: new Date(interval.start.setDate(interval.start.getDate() + 7 * period)),
        end: new Date(interval.end.setDate(interval.end.getDate() + 7 * period)),
      });
    } else if (intervalType === 'month') {
      setInterval({
        start: new Date(interval.start.setMonth(interval.start.getMonth() + 1 * period)),
        end: new Date(interval.end.setMonth(interval.end.getMonth() + 1 * period)),
      });
    } else if (intervalType === 'year') {
      setInterval({
        start: new Date(interval.start.setFullYear(interval.start.getFullYear() + 1 * period)),
        end: new Date(interval.end.setFullYear(interval.end.getFullYear() + 1 * period)),
      });
    }
  };

  return (
    <StatisticsContext.Provider
      value={{
        intervalType,
        setIntervalType,
        interval,
        setInterval,
        nextPeriod,
        prevPeriod,
        data,
      }}
    >
      <StatisticsHeader />
      <IntervalSelect />
      {!loading ? children : <LoadingPage minHeight="100" />}
    </StatisticsContext.Provider>
  );
};
