import { useContext, useState, useEffect, useRef, useMemo } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import Timer from '@/components/utils/Timer';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { InstanceContext } from '@/contexts/InstanceContext';
import { getActivityColor } from '@/components/utils/CalendarFns';
import SuccessAnimation from '@/components/utils/SuccessAnimation';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { SupervisorContext } from 'src/contexts/SupervisorContext';

const transitionDuration = 500; //ms

type Props = {
  height?: string;
  hideRunningTimer?: boolean;
  switchImages?: boolean;
  displayQuarters?: boolean;
};

const styles = {
  imageContainer: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '10%',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: '50%',
    height: '100%',
    objectFit: 'cover',
    transform: 'translateX(-50%)',
  },
};

const ActivityImage = (props: Props) => {
  const { isStarted, timerPaused, setTimerPaused, instance } = useContext(InstanceContext);
  const { isSupervising } = useContext(SupervisorContext);
  const { activity } = useContext(ActivityContext);
  const [imageLoadError, setImageLoadError] = useState(false);
  const isTimerRunning = isStarted && !timerPaused && !isSupervising;
  const theme = useTheme();
  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(props.height ?? '0px');

  const pauseTimer = () => {
    setTimerPaused(!timerPaused);
  };

  useEffect(() => {
    if (containerRef.current && !props.height) {
      const maxHeight = 300;
      const rect = containerRef.current.getBoundingClientRect();
      const dynamicHeight = rect.width / (16 / 10);
      setHeight(`${Math.min(dynamicHeight, maxHeight)}px`); // Assumes a 16:9 aspect ratio
    }
  }, [containerRef]);

  const showPicture = useMemo(() => {
    if (
      !imageLoadError &&
      ((isStarted && props.switchImages) || (!isStarted && !props.switchImages))
    ) {
      return !!activity.picture;
    }
    return false;
  }, [isStarted, imageLoadError]);

  return (
    <Stack sx={styles.imageContainer} style={{ height }} ref={containerRef}>
      <Box sx={styles.image}>
        {activity.picture && (
          <img
            src={activity.picture}
            alt="activity img"
            onError={() => setImageLoadError(true)}
            style={{
              height,
              transform: showPicture ? 'scale(1, 1)' : 'scale(0, 0)',
              transformOrigin: 'center',
              transition: `transform ${transitionDuration}ms`,
              borderRadius: '5%',
            }}
          />
        )}
      </Box>
      <Box sx={styles.image}>
        <Box
          style={{
            height,
            transform: !showPicture ? 'scale(1, 1)' : 'scale(0, 0)',
            transformOrigin: 'center',
            transition: `transform ${transitionDuration}ms`,
            aspectRatio: '1 / 1',
          }}
        >
          {!instance.dateIsCompleted ? (
            <Timer
              minutes={activity.duration}
              isRunning={isTimerRunning}
              timerId={instance.activityInstanceId}
              displayMode={props.displayQuarters ? 'quarters' : 'default'}
              color={getActivityColor(theme.palette, activity.duration)}
              onClick={isStarted ? pauseTimer : undefined}
              completed={!!instance.dateIsCompleted}
            />
          ) : (
            <Stack
              justifyContent="center"
              alignItems="center"
              height="100%"
              spacing={1}
              sx={{
                border: `2px solid ${theme.palette.success.main}`,
                borderRadius: '50%',
                aspectRatio: '1 / 1',
              }}
            >
              <SuccessAnimation />
              <Typography variant="h6" fontWeight={'bold'} textAlign={'center'}>
                {t('Activity Completed')}
              </Typography>
              <Typography variant="caption" paragraph>
                {format(instance.dateIsCompleted, 'yyyy-MM-dd HH:mm')}
              </Typography>
            </Stack>
          )}
        </Box>
      </Box>
    </Stack>
  );
};

export default ActivityImage;
