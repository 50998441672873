export const getTimezone = (): string => {
  const options: Intl.DateTimeFormatOptions = {
    timeZoneName: 'long',
  };
  const formatter: Intl.DateTimeFormat = new Intl.DateTimeFormat([], options);
  const timeZone: string | undefined = formatter.resolvedOptions().timeZone;
  return timeZone ?? '';
};

export const isSameTimeZoneOffset = (timeZone1: string, timeZone2: string): boolean => {
  const date = new Date();
  const dateStr1 = date.toLocaleString('en-US', { timeZone: timeZone1 });
  const dateStr2 = date.toLocaleString('en-US', { timeZone: timeZone2 });
  return dateStr1 === dateStr2;
};
