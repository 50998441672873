import { Button, Dialog, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

const urls = {
  privacyEn:
    'https://knowit-terms-of-service-and-privacy-policy.s3.eu-north-1.amazonaws.com/todej/privacy-policy-en.html',
  privacySv:
    'https://knowit-terms-of-service-and-privacy-policy.s3.eu-north-1.amazonaws.com/todej/privacy-policy-sv.html',
  termsEn:
    'https://knowit-terms-of-service-and-privacy-policy.s3.eu-north-1.amazonaws.com/todej/terms-of-service-en.html',
  termsSv:
    'https://knowit-terms-of-service-and-privacy-policy.s3.eu-north-1.amazonaws.com/todej/terms-of-service-sv.html',
  licenseEn:
    'https://knowit-terms-of-service-and-privacy-policy.s3.eu-north-1.amazonaws.com/todej/license.html',
  licenseSv:
    'https://knowit-terms-of-service-and-privacy-policy.s3.eu-north-1.amazonaws.com/todej/license-sv.html',
};

export type LegalType =
  | 'privacyEn'
  | 'privacySv'
  | 'termsEn'
  | 'termsSv'
  | 'licenseEn'
  | 'licenseSv'
  | '';

type LegalDialogProps = {
  open: LegalType;
  onClose: () => void;
};

const LegalDialog = (props: LegalDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getFrame = () => {
    if (props.open) return urls[props.open];

    return '';
  };

  return (
    <>
      <Dialog open={props.open.length > 0} onClose={props.onClose} fullScreen={true}>
        <Stack
          p={0}
          sx={{
            height: '100%',
            backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'white',
          }}
        >
          <iframe src={getFrame()} title="Legal text" style={{ height: '100%', width: '100%' }} />
        </Stack>
        <Button sx={{ py: 2 }} onClick={props.onClose} startIcon={<CloseIcon />}>
          {t('Close')}
        </Button>
      </Dialog>
    </>
  );
};

export default LegalDialog;
