import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LegalDialog, { LegalType } from 'src/components/utils/dialogs/LegalDialog';

const LegalSetting = () => {
  const { t, i18n } = useTranslation();
  const [legalText, setLegalText] = useState<LegalType>('');
  const lang = i18n.language.split('-')[0];

  return (
    <>
      <LegalDialog open={legalText} onClose={() => setLegalText('')} />
      <Stack spacing={2} padding={'16px'}>
        <Button
          color="primary"
          onClick={() => setLegalText(lang === 'sv' ? 'licenseSv' : 'licenseEn')}
        >
          {t('Licenses')}
        </Button>
        <Button
          color="primary"
          onClick={() => setLegalText(lang === 'sv' ? 'privacySv' : 'privacyEn')}
        >
          {t('Privacy Policy')}
        </Button>
        <Button color="primary" onClick={() => setLegalText(lang === 'sv' ? 'termsSv' : 'termsEn')}>
          {t('Terms of Service')}
        </Button>
      </Stack>
    </>
  );
};

export default LegalSetting;
