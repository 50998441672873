import { Dialog, Stack, DialogTitle, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UploadImageButton from './UploadImageButton';
import SelectIconButton from './SelectIconButton';
import GenerateImageButton from './GenerateImageButton';

type SelectFileDialogProps = {
  open: boolean;
  onLoad: () => void;
  onSelect: (file: File | null) => void;
  onClose: () => void;
  disableAI?: boolean;
};

const SelectFileDialog = (props: SelectFileDialogProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          props.onClose();
        }}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle textAlign={'center'}>{t('Add an image')}</DialogTitle>
        <DialogContent sx={{ px: 1 }}>
          <Stack direction={'row'} justifyContent="space-evenly" alignItems="start" mt={1}>
            <UploadImageButton onSelectFile={props.onSelect} />
            <SelectIconButton onSelectFile={props.onSelect} />
            {props.disableAI ? null : (
              <GenerateImageButton onLoad={props.onLoad} onSelectFile={props.onSelect} />
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SelectFileDialog;
