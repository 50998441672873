import { Note } from '@api';
import { Close, Edit } from '@mui/icons-material';
import { Box, DialogContent, DialogTitle, Theme, Typography, lighten } from '@mui/material';

type ViewNoteProps = {
  onEdit: () => void;
  note: Note;
  onClose: () => void;
};

const ViewNote = ({ note, onEdit, onClose }: ViewNoteProps) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: note.color,
          px: 2,
          color: (theme) =>
            theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.black,
        }}
      >
        <Edit onClick={onEdit}></Edit>
        <DialogTitle sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {note.title}
        </DialogTitle>
        <Close onClick={onClose}></Close>
      </Box>
      <DialogContent
        sx={{
          background: lighten(note.color, 0.7),
          color: (theme) =>
            theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.black,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            minHeight: '10em',
            pt: 2,
            wordBreak: 'break-word',
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-line',
          }}
        >
          {note.text}
        </Typography>
      </DialogContent>
    </>
  );
};

export default ViewNote;
