import { Box } from '@mui/material';
import { CalendarDayContext } from '@/contexts/CalendarDayContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { ActivityInstance } from '@api';
import ClusterRenderer from './ClusterRenderer';
import { breakActivities, clusterActivities } from '@/components/utils/CalendarFns';
import { format } from 'date-fns';
import { useTheme } from '@mui/material/styles';
//A component that renders each day
const CalendarDay = () => {
  const [clusters, setClusters] = useState<ActivityInstance[][]>([]);

  const calendarRef = useRef(null);

  const theme = useTheme();

  const { instances, date } = useContext(CalendarDayContext);

  const today = new Date();

  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();

  const day = format(date, 'EEEE').toLowerCase();
  const dayColor = (theme.palette.weekdays as any)[day].light ?? 'white';
  useEffect(() => {
    if (instances.length > 0) {
      //Sort instances by duration

      const multiDayInstances = breakActivities(instances, date);

      const sortedInstances = multiDayInstances.sort((a, b) => b.duration - a.duration);

      // Turn array of instances into array of clusters
      const result = clusterActivities(sortedInstances);
      setClusters(result);
    } else {
      setClusters([]);
    }
  }, [instances]);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        flexGrow: 1,
        width: '10px', //Placeholder, they will flex-grow to fill the remaining space
        mx: '1px',
        backgroundColor: isToday ? dayColor : 'white',
      }}
      ref={calendarRef}
    >
      {clusters.map((cluster) => (
        <ClusterRenderer
          cluster={cluster}
          parentRef={calendarRef}
          key={cluster[0].activityInstanceId}
        />
      ))}
    </Box>
  );
};

export default CalendarDay;
