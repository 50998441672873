import { useEffect, useState } from 'react';
import { TextField, Stack, Typography } from '@mui/material';
import { MulberryIcon } from '@api';
import IconItem from './IconItem';
import IconList from './IconList';
import { MulberryIconsApi } from '@api';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';

const IconSearch = ({
  onSelectIcon,
  selectedIcon,
  onChange,
}: {
  onSelectIcon: (icon: MulberryIcon) => void;
  selectedIcon?: MulberryIcon;
  onChange: (searching: boolean) => void;
}) => {
  const [search, setSearch] = useState<string>('');
  const [searchItems, setSearchItems] = useState<MulberryIcon[]>([]);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Define a timer variable
    setLoading(true);
    const timer = setTimeout(() => {
      const searchIcons = async () => {
        const lang = i18n.language.split('-')[0];

        const res = await new MulberryIconsApi().getIconsBySearch({
          searchString: search,
          language: lang,
        });
        setSearchItems(res || []);
        setLoading(false);
      };

      if (search.length > 0) {
        searchIcons();
      } else {
        setSearchItems([]);
        setLoading(false);
      }
    }, 500); // 0.5 second delay

    // Clear the timer if `search` changes before 1 second
    return () => clearTimeout(timer);
  }, [search]);

  return (
    <>
      <TextField
        id="outlined-search"
        label={t('Search')}
        type="search"
        sx={{ m: 2 }}
        value={search}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value.length > 0);
          setSearch(event.target.value);
        }}
      />
      {search.length > 0 && (
        <>
          {searchItems.length ? (
            <IconList>
              {searchItems.map((icon) => (
                <IconItem
                  icon={icon}
                  key={icon.symbol}
                  onClick={() => onSelectIcon(icon)}
                  selectedIcon={selectedIcon}
                />
              ))}
            </IconList>
          ) : (
            <>
              {loading ? (
                <Stack alignItems={'center'} py={2}>
                  <CircularProgress />
                </Stack>
              ) : (
                <Stack alignItems={'center'} py={2}>
                  <SearchIcon />
                  <Typography variant="body1">{t('No results found')}</Typography>
                </Stack>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default IconSearch;
