import { Accordion, AccordionSummary, AccordionDetails, Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MulberryIconsApi } from '@api';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MulberryIcon } from '@api';
import CircularProgress from '@mui/material/CircularProgress';
import IconItem from './IconItem';
import IconList from './IconList';

const IconCategory = ({
  category,
  open,
  onChange,
  onSelectIcon,
  selectedIcon,
}: {
  category: string;
  open: string | false;
  onChange: (category: string) => void;
  onSelectIcon: (icon: MulberryIcon) => void;
  selectedIcon?: MulberryIcon;
}) => {
  const { t } = useTranslation();
  const [icons, setIcons] = useState<MulberryIcon[]>([]);
  const [loading, setLoading] = useState(true);

  const getIcons = async () => {
    setLoading(true);
    const res = await new MulberryIconsApi().getIconsByCategory({ category: category });
    //Make sure we have not unmounted
    if (open === category) {
      setIcons(res || []);
      setLoading(false);
    }
  };

  const onOpen = () => {
    getIcons();
  };

  const onClose = () => {
    if (icons.length > 0) {
      setIcons([]);
    }
  };

  useEffect(() => {
    if (open === category) {
      onOpen();
    } else {
      onClose();
    }
  }, [open]);

  return (
    <Accordion
      onChange={() => onChange(category)}
      expanded={open === category}
      disableGutters={true}
      TransitionProps={{ timeout: { appear: 200, enter: 200, exit: 20 } }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{t(category)}</AccordionSummary>
      <AccordionDetails sx={{ px: 0 }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <IconList>
            {icons.map((icon) => (
              <IconItem
                icon={icon}
                key={icon.symbol}
                onClick={() => {
                  onSelectIcon(icon);
                }}
                selectedIcon={selectedIcon}
              />
            ))}
          </IconList>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default IconCategory;
