import { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import InstallPWA from 'src/components/utils/InstallPWA';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import StartPageTitle from 'src/components/start/StartPageTitle';
import LoginHeader from 'src/components/start/LoginHeader';
import InfoComponent, { InfoComponentProps } from 'src/components/start/InfoComponent';
import AppDescription from 'src/components/start/AppDescription';
import BuyTodej from 'src/components/start/BuyTodej';
import { Link } from '@mui/material';

import { styled } from '@mui/system';
import LegalDialog, { LegalType } from 'src/components/utils/dialogs/LegalDialog';

const Footer = styled('div')({
  width: '100%',
});

const StyledLink = styled(Link)({
  cursor: 'pointer',
});

const InfoComponents: InfoComponentProps[] = [
  {
    image: 'start/all_platforms.png',
    title: 'Use the app wherever you are',
    description: 'Use the app wherever you are description',
    color: '#fff0f0',
  },
  {
    image: 'start/week_view.png',
    title: 'Plan your week',
    description: 'Plan your week description',
    color: '#E0F2FF',
  },
  {
    image: 'start/ratings.png',
    title: 'Track your mood',
    description: 'Track your mood description',
    color: '#FEFFE0',
  },
  {
    image: 'start/notes.png',
    title: 'Organize your notes',
    description: 'Organize your notes description',
    color: '#F3E0FF',
  },
  {
    image: 'start/holding_phone.png',
    title: 'Supervisor-mode',
    description: 'Supervisor-mode description',
    color: '#fff0f0',
  },
  {
    image: 'start/select_duration.png',
    title: 'Easy to use',
    description: 'Easy to use description',
    color: '#E0F2FF',
  },
];

const StartPage = () => {
  const { t, i18n } = useTranslation();
  const { palette } = useTheme();
  const lang = i18n.language.split('-')[0];

  const [legalText, setLegalText] = useState<LegalType>('');

  return (
    <>
      <LoginHeader />

      <Box
        sx={(theme) => ({
          px: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        })}
      >
        <Stack alignItems="center" sx={{ width: '100%', maxWidth: '70em', pt: 2 }}>
          <StartPageTitle />
          <AppDescription />
          <Stack direction="row" flexWrap={'wrap'} sx={{ py: 4 }} alignItems={'start'}>
            {InfoComponents.map((info) => (
              <InfoComponent key={info.title} {...info} />
            ))}
          </Stack>
          <BuyTodej />
          <InstallPWA />
          <Footer>
            <Typography variant="subtitle1" textAlign={'center'} gutterBottom>
              {t('Legal & Licenses')}
            </Typography>
            <Stack direction="row" spacing={2} justifyContent={'center'} marginBottom={'48px'}>
              <LegalDialog open={legalText} onClose={() => setLegalText('')} />

              <StyledLink onClick={() => setLegalText(lang === 'sv' ? 'licenseSv' : 'licenseEn')}>
                {t('Licenses')}
              </StyledLink>

              <StyledLink onClick={() => setLegalText(lang === 'sv' ? 'privacySv' : 'privacyEn')}>
                {t('Privacy Policy')}
              </StyledLink>
              <StyledLink onClick={() => setLegalText(lang === 'sv' ? 'termsSv' : 'termsEn')}>
                {t('Terms of Service')}
              </StyledLink>
            </Stack>
          </Footer>
        </Stack>
      </Box>
    </>
  );
};

export default StartPage;
