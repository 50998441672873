import React, { useState } from 'react';
import {
  Button,
  Stack,
  Dialog,
  Box,
  DialogActions,
  Typography,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import IconSelector from '@/components/icons-selector/IconSelector';

const SelectIconButton = ({ onSelectFile }: { onSelectFile: (file: File) => void }) => {
  const { t } = useTranslation();

  const [selectorOpen, setSelectorOpen] = useState(false);
  const [icon, setIcon] = useState<string | undefined>(undefined);
  const [showError, setShowError] = useState(false);

  const handleButtonClick = () => {
    setSelectorOpen(true);
  };

  const onSelect = (icon: string) => {
    setIcon(icon);
  };

  const fetchIcon = async (icon: string) => {
    const baseUrl = 'https://tomorrow-icons.s3.eu-north-1.amazonaws.com/';
    const stringUrl = baseUrl + icon + '.svg';
    try {
      const response = await fetch(stringUrl, {
        headers: {
          'Content-Type': 'image/svg+xml', // Ensure the correct content type is requested
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const file = new File([blob], icon + '.svg', { type: 'image/svg+xml' });
        onSelectFile(file);
      } else {
        setShowError(true);
      }
    } catch (e) {
      setShowError(true);
    }
    setSelectorOpen(false);
  };

  const onConfirm = () => {
    if (icon) {
      fetchIcon(icon);
    } else {
      setSelectorOpen(false);
    }
  };

  return (
    <>
      <Button onClick={handleButtonClick}>
        <Stack alignItems={'center'}>
          <PermMediaIcon fontSize="large" />
          {t('Icon library')}
        </Stack>
      </Button>
      <Dialog open={showError} onClose={() => setShowError(false)} sx={{ m: 0 }}>
        <DialogTitle>{t('Error')}</DialogTitle>
        <DialogContent>
          <Typography>
            {t('Something went wrong while loading the icon. Please try again.')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowError(false)}>{t('Ok')}</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={selectorOpen}
        onClose={() => setSelectorOpen(false)}
        fullWidth
        sx={{ m: 0 }}
        BackdropProps={{ style: { background: 'none' } }}
        PaperProps={{
          sx: {
            height: '100%',
          },
        }}
      >
        <DialogContent sx={{ px: 0 }}>
          <IconSelector onSelect={onSelect} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectorOpen(false)}>{t('Cancel')}</Button>
          <Button onClick={onConfirm}>{t('Select')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectIconButton;
