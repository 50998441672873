import { forwardRef, HTMLAttributes, useEffect, useRef } from 'react';
import {
  Stack,
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { SubtaskItem as SubtaskItemType } from '@api';
import ImageInput from '../imageInput/ImageInput';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';

export type SubtaskItemProps = HTMLAttributes<HTMLDivElement> & {
  item: SubtaskItemType;
  invisible?: boolean;
  isDragging?: boolean;
  onUpdate?: (newVal: SubtaskItemType) => void;
  onDelete?: (id: number) => void;
};

const SubtaskItem = forwardRef<HTMLDivElement, SubtaskItemProps>(
  ({ item, invisible, isDragging, onDelete, onUpdate, style, ...props }, ref) => {
    const active = useRef(false);
    const { attributes, listeners } = useSortable({ id: item.subtaskId });
    const [error, setError] = useState<null | string>(null);
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    useEffect(() => {
      if (invisible && active.current) {
        active.current = false;
      }
    }, [invisible]);

    const handleFileChange = (file: File) => {
      setError(null);
      if (file) {
        if (file.type && file.type.startsWith('image/')) {
          onUpdate?.({
            ...item,
            picture: URL.createObjectURL(file),
            pictureFile: file,
            pictureEdited: true,
          });
        } else {
          setError(t('Only images are allowed'));
        }
      }
    };

    const onDeleteItem = () => {
      onDelete?.(item.subtaskId);
      setOpen(false);
    };

    return (
      <>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          ref={ref}
          sx={{
            py: 1,
            width: `100%`,
            opacity: invisible ? '0' : '1',
            borderRadius: '5px',
            cursor: isDragging ? 'grabbing' : 'grab',
            transform: isDragging ? 'rotate(5deg)' : 'rotate(0deg)',
            transformOrigin: 'left',
            boxShadow: isDragging ? 10 : 2,
            overflow: 'hidden',
            background: (theme) => theme.palette.background.paper,
            my: 0.4,
            ...style,
          }}
          {...props}
        >
          <Box
            sx={{
              height: '3em',
              width: '4em',
              cursor: 'grab',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 0,
              marginLeft: '-1em',
            }}
            {...attributes}
            {...listeners}
          >
            <DragIndicatorIcon />
          </Box>
          <ImageInput
            value={item.picture}
            onChange={handleFileChange}
            sx={{ width: '5em', height: '5em', flexGrow: 0 }}
            disableAI
          />
          <TextField
            type="text"
            sx={{
              ml: 2,
              flexGrow: 1,
            }}
            onChange={(e) => {
              onUpdate?.({ ...item, subtaskText: e.target.value });
            }}
            value={item.subtaskText}
            placeholder={t('Add text here') ?? ''}
            InputProps={{
              sx: {
                borderRadius: '1em',
              },
            }}
            autoComplete="off"
            name="subtaskText"
          />
          <IconButton
            color="error"
            sx={{ flexGrow: 0 }}
            aria-label="Remove item"
            onClick={() => setOpen(true)}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('Are you sure you want to delete this item?')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>{t('Cancel')}</Button>
            <Button onClick={() => onDeleteItem()} autoFocus color="error">
              {t('Remove')}
            </Button>
          </DialogActions>
        </Dialog>
        <Typography variant="caption" sx={{ color: 'red' }}>
          {error}
        </Typography>
      </>
    );
  },
);

export default SubtaskItem;
