import {
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import AiLegalDialog from '@/components/utils/dialogs/AiLegalDialog';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const AiOnboardingDialog = () => {
  const { t } = useTranslation();
  const [legalDialogOpen, setLegalDialogOpen] = useState(false);
  const [isAIEnabled, setIsAIEnabled] = useState(localStorage.getItem('aiEnabled') === 'true');
  const [onBoardingDismissed, setOnBoardingDismissed] = useState(
    localStorage.getItem('aiOnboardingDismissed') != null,
  );

  const [onboardingDialogOpen, setOnboardingDialogOpen] = useState(
    !onBoardingDismissed && !isAIEnabled,
  );

  const dismissOnboarding = () => {
    localStorage.setItem('aiOnboardingDismissed', 'true');
    setOnBoardingDismissed(true);
  };

  const enableAI = () => {
    localStorage.setItem('aiEnabled', 'true');
    setIsAIEnabled(true);
    window.dispatchEvent(new Event('storage'));
    setLegalDialogOpen(false);
  };

  return (
    <>
      <Dialog
        open={onboardingDialogOpen}
        onClose={() => {
          setOnboardingDialogOpen(false);
        }}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>{t('New functionality!')}</DialogTitle>
        <DialogContent>
          <Stack>
            <Typography>
              {t('You can now utulize the power of AI to improve your experience with todej.')}
            </Typography>
            <Typography>
              {t(
                'The Ai can help you with planning new activities, analyze statistics and improve your overall experience.',
              )}
            </Typography>
            <Typography>{t('You can always change this setting under:')}</Typography>
            <Stack alignItems="center" direction="row" gap={0.5}>
              <Typography fontWeight={'bold'}>{t('Settings')}</Typography>
              <ArrowRightAltIcon />
              <Typography fontWeight={'bold'}>{t('AI-Settings')}</Typography>
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setOnboardingDialogOpen(false);
              dismissOnboarding();
            }}
          >
            {t('Not now')}
          </Button>
          <Button
            onClick={() => {
              dismissOnboarding();
              setLegalDialogOpen(true);
              setOnboardingDialogOpen(false);
            }}
            sx={{
              fontWeight: 'bold',
            }}
          >
            {t('Activate AI')}
          </Button>
        </DialogActions>
      </Dialog>

      <AiLegalDialog
        open={legalDialogOpen}
        onEnable={enableAI}
        onClose={() => setLegalDialogOpen(false)}
      />
    </>
  );
};
export default AiOnboardingDialog;
