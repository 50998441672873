export const compressImage = async (
  img: HTMLImageElement,
  quality: number,
  maxWidth: number,
  maxHeight: number,
): Promise<Blob | null> => {
  return new Promise<Blob | null>((resolve) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;

    let newWidth = img.width;
    let newHeight = img.height;

    if (newWidth > maxWidth) {
      const ratio = maxWidth / newWidth;
      newWidth = maxWidth;
      newHeight *= ratio;
    }

    if (newHeight > maxHeight) {
      const ratio = maxHeight / newHeight;
      newHeight = maxHeight;
      newWidth *= ratio;
    }

    canvas.width = newWidth;
    canvas.height = newHeight;
    ctx.drawImage(img, 0, 0, newWidth, newHeight);

    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      'image/png',
      quality,
    );
  });
};

export const compressImageToSize = async (
  file: File,
  maxSize: number,
  maxWidth = 800,
  maxHeight = 800,
): Promise<Blob | null> => {
  let quality = 1.0; // Starting quality

  if (file.size < maxSize) {
    return file;
  }

  const img = new Image();
  img.src = URL.createObjectURL(file);

  // Add error handling for invalid image URLs
  if (!img.src) {
    console.error('Invalid image URL.');
    return null;
  }
  try {
    await img.decode();
  } catch (error) {
    console.error('Error decoding the image:', error);
    return null;
  }

  let blob = await compressImage(img, quality, maxWidth, maxHeight);

  // eslint-disable-next-line functional/no-loop-statement
  while (blob && blob?.size > maxSize && quality > 0) {
    quality -= 0.25; // Decrease quality (adjust this increment as needed)
    blob = await compressImage(img, quality, maxWidth, maxHeight);
  }

  return quality > 0 ? blob : null;
};
