import React, { useRef } from 'react';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const UploadImageButton = ({ onSelectFile }: { onSelectFile: (image: File) => void }) => {
  const { t } = useTranslation();

  const imageInputRef = useRef<HTMLInputElement>(null);

  const selectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      onSelectFile(file);
    }
  };

  const handleButtonClick = () => {
    if (imageInputRef.current) imageInputRef.current.click();
  };

  return (
    <>
      <input
        type="file"
        accept="image/*"
        onChange={selectImage}
        style={{ display: 'none' }}
        ref={imageInputRef}
        id={'UploadImage'}
      />
      <Button onClick={handleButtonClick}>
        <Stack alignItems={'center'}>
          <CameraAltIcon fontSize="large" />
          {t('Upload Image')}
        </Stack>
      </Button>
    </>
  );
};

export default UploadImageButton;
