import { useContext, useEffect } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import { useTranslation } from 'react-i18next';
import { Box, Container, IconButton, Stack, Typography } from '@mui/material';
import TitleForm from '../createActivity/TitleForm';
import DateForm from '../createActivity/DateForm';
import DurationForm from '../createActivity/DurationForm';
import MoreOptionsForm from '../createActivity/moreOptions/MoreOptionsForm';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate } from 'react-router';
import { ActivityFormContext } from '@/contexts/ActivityFormContext';
import { validateName, validateDescription } from '@/components/utils/Validate';
import DeleteActivityActions from './DeleteActivityActions';
import DeleteInstanceAction from './DeleteInstanceAction';
import { EditActivityParams } from 'src/pages/activity/InstanceAndActivityProvider';
import { useParams } from 'react-router-dom';

const EditActivityForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { editSingleActivity } = useParams<EditActivityParams>();
  const isEditingSingleActivity = editSingleActivity === 'single';

  const { activity } = useContext(ActivityContext);
  const formHandler = useContext(ActivityFormContext);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const nameError = validateName(activity.activityName, true, t);
    const startError = activity.startDate ? null : t('Start date is required');
    const durationError =
      activity.duration || activity.duration === 0 ? null : t('Duration is required');
    const descriptionError = validateDescription(activity.description, t);
    if (!nameError && !startError && !durationError && !descriptionError) {
      formHandler.setValid(true);
    } else {
      formHandler.setValid(false);
    }
  }, [activity]);

  return (
    <>
      <Box sx={{ m: 2, position: 'relative' }}>
        <IconButton
          sx={{ position: 'absolute', padding: 1, bgcolor: 'line', top: 0, bottom: 0 }}
          onClick={handleBack}
        >
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography variant="h6" sx={{ flex: 1, textAlign: 'center', padding: 0.5 }}>
          {t('Edit Activity')}
        </Typography>
        {activity.recurringActivity && !isEditingSingleActivity ? (
          <DeleteActivityActions />
        ) : (
          <DeleteInstanceAction />
        )}
      </Box>
      <Container sx={{ py: 3 }}>
        <Stack spacing={4}>
          <Stack>
            <TitleForm errorOnEmpty validateForm={false} showSuggestions={false}></TitleForm>
          </Stack>
          <Stack>
            <DateForm validateForm={false} useInstance={isEditingSingleActivity}></DateForm>
          </Stack>
          <Stack>
            <DurationForm validateForm={false}></DurationForm>
          </Stack>
          <Stack>
            <MoreOptionsForm
              noHeader
              validateForm={false}
              hideRecurrence={isEditingSingleActivity}
            ></MoreOptionsForm>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default EditActivityForm;
