import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AiApi } from '@api';
import { useContext } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

const GenerateImageButton = ({
  onLoad,
  onSelectFile,
}: {
  onLoad: () => void;
  onSelectFile: (file: File | null) => void;
}) => {
  const { activity, imageCache, setImageCache } = useContext(ActivityContext);
  const { t } = useTranslation();

  const handleButtonClick = async () => {
    //Chech if we have a cache for the activity
    if (imageCache && imageCache.title === activity.activityName) {
      onSelectFile(imageCache.image);
      return;
    } else if (imageCache) {
      // The cache is for a different activity
      setImageCache?.(null);
    }

    onLoad();
    const data = await new AiApi().generateImage(activity);
    if (data) {
      setImageCache?.({ title: activity.activityName, image: data });
      onSelectFile(data);
    } else {
      onSelectFile(null);
    }
  };

  return (
    <Button onClick={handleButtonClick}>
      <Stack alignItems={'center'}>
        <AutoFixHighIcon fontSize="large" />
        {t('Generate image')}
      </Stack>
    </Button>
  );
};

export default GenerateImageButton;
