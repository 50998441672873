import { Stack } from '@mui/material';
import { ReactNode } from 'react';

const IconList = ({ children }: { children: ReactNode }) => {
  return (
    <Stack flexDirection={'row'} flexWrap={'wrap'} spacing={0} rowGap={0} justifyContent={'left'}>
      {children}
    </Stack>
  );
};

export default IconList;
