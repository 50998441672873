/* tslint:disable */
/* eslint-disable */

import { BaseAPI } from '../baseApi';
import { MulberryIcon } from '../types';
import * as runtime from '../runtime';

export interface GetIconsByCategoryRequest {
  category: String;
}

export interface GetIconsBySearchRequest {
  searchString: String;
  language: String;
}

export class MulberryIconsApi extends BaseAPI {
  async getIconsBySearch(
    requestParameters: GetIconsBySearchRequest,
  ): Promise<Array<MulberryIcon> | undefined> {
    const queryParameters: any = {};

    queryParameters['searchString'] = requestParameters.searchString.toLowerCase();
    queryParameters['language'] = requestParameters.language.toLowerCase();

    try {
      const response = await this.request({
        path: `/icons`,
        method: 'GET',
        query: queryParameters,
      });

      return await new runtime.JSONApiResponse(
        response,
        (jsonValue): Array<MulberryIcon> => jsonValue,
      ).value();
    } catch (error) {}
  }

  async getIconsByCategory(
    requestParameters: GetIconsByCategoryRequest,
  ): Promise<Array<MulberryIcon> | undefined> {
    const queryParameters: any = {};

    queryParameters['category'] = requestParameters.category;

    try {
      const response = await this.request({
        path: `/icons/category`,
        method: 'GET',
        query: queryParameters,
      });

      return await new runtime.JSONApiResponse(
        response,
        (jsonValue): Array<MulberryIcon> => jsonValue,
      ).value();
    } catch (error) {}
  }

  async getIconCategories(): Promise<Array<string> | undefined> {
    const queryParameters: any = {};
    try {
      const response = await this.request({
        path: `/icons/categories`,
        method: 'GET',
        query: queryParameters,
      });

      return await new runtime.JSONApiResponse(
        response,
        (jsonValue): Array<string> => jsonValue,
      ).value();
    } catch (error) {}
  }
}
