import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MulberryIconsApi } from '@api';
import { useEffect, useState } from 'react';
import IconCategory from './IconCategory';
import IconSearch from './IconSearch';
import { MulberryIcon } from '@api';

const IconSelector = ({ onSelect }: { onSelect: (icon: string) => void }) => {
  const { t } = useTranslation();

  const [selectedIcon, setSelectedIcon] = useState<MulberryIcon | undefined>(undefined);

  const [categories, setCategories] = useState<string[]>([]);

  const [open, setOpen] = useState<string | false>(false);

  const [searching, setSearching] = useState(false);

  const selectIcon = (icon: MulberryIcon) => {
    setSelectedIcon(icon);
    onSelect(icon.symbol);
  };

  const getCategories = async () => {
    const res = await new MulberryIconsApi().getIconCategories();
    if (res) {
      setCategories(res);
    }
  };

  const handleChange = (categpry: string) => {
    if (open === categpry) {
      setOpen(false);
    } else {
      setOpen(categpry);
    }
  };

  //UseEffect
  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Stack>
      <Typography variant="h6" px={2}>
        {t('Select icon')}
      </Typography>
      <IconSearch
        onSelectIcon={selectIcon}
        selectedIcon={selectedIcon}
        onChange={(searching) => setSearching(searching)}
      />
      {!searching &&
        categories.map((category) => (
          <IconCategory
            category={category}
            open={open}
            onChange={handleChange}
            key={category}
            onSelectIcon={selectIcon}
            selectedIcon={selectedIcon}
          />
        ))}
    </Stack>
  );
};

export default IconSelector;
