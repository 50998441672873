import { MulberryIcon } from '@api';
import { Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const IconItem = ({
  icon,
  selectedIcon,
  onClick,
}: {
  icon: MulberryIcon;
  selectedIcon?: MulberryIcon;
  onClick: () => void;
}) => {
  const baseUrl = 'https://tomorrow-icons.s3.eu-north-1.amazonaws.com/preview/';
  const isSelected = selectedIcon?.symbol === icon.symbol;

  return (
    <Button
      sx={{
        m: 1,
        p: 0,
        border: isSelected ? '3px solid' : '',
        position: 'relative',
        width: '110px',
      }}
      onClick={onClick}
    >
      {isSelected && <CheckCircleIcon sx={{ position: 'absolute', top: 5, right: 5 }} />}
      <img
        loading="lazy"
        src={baseUrl + icon.symbol + '.png'}
        alt={icon.symbol}
        style={{ width: '110px', userSelect: 'none' }}
        draggable={false}
      />
    </Button>
  );
};

export default IconItem;
